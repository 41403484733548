<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>Formula Médica - Medicamentos</page-header-compact>
        <div class="container-xl px-4 mt-n15">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                      <formulaMedicaComponent :usuario="usuario" :idHistoria="idHistoria"></formulaMedicaComponent>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import Toast from '../../../../components/common/utilities/toast';
import formulaMedicaComponent from './formulaMedicaComponent';
import historiaClinicaService from '../../../../services/historiaClinicaService';

export default {
    components: {modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact,formulaMedicaComponent},
    data(){
        return {
            usuario: {},
            formula:{},
            idHistoria:''
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await historiaClinicaService.show(id);
            this.usuario = response.data.usuario;
        },
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            this.idHistoria = this.$route.params.id_historia;
            await this.cargarUsuario(this.idHistoria);
            
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
        
    }
}
</script>